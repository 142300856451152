<template>
  <div>
    <div class="uk-flex uk-flex-between uk-flex-middle">
      <div class="uk-text-large">Usuarios</div>
      <div>
        <button
          class="uk-button uk-button-primary uk-border-rounded"
          href="#edit-user-modal"
          uk-toggle
          @click="setNewMode"
        >
          Crear nuevo usuario
        </button>
      </div>
    </div>

    <table
      class="uk-table uk-table-striped uk-table-middle uk-table-hover uk-table-divider uk-visible@m"
    >
      <thead>
        <tr>
          <th>Nombre completo</th>
          <th>Nickname</th>
          <th>Correo</th>
          <th>Empresa</th>
          <th>Tipo</th>
          <th>Activo</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.first_name }} {{ user.last_name }}</td>
          <td>{{ user.nickname }}</td>
          <td>{{ user.email }}</td>
          <td>
            <div v-if="user.business">
              <div class="uk-text-bold">
                {{ user.business.name }}
              </div>
              <div>{{ user.job_title }}</div>
            </div>
          </td>
          <td>{{ user.type.name }}</td>
          <td>
            <span v-if="user.active === 1" uk-icon="check"></span>
          </td>
          <td>
            <div class="uk-text-right">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <router-link
                  class="uk-button uk-button-default"
                  uk-icon="expand"
                  :to="'/users/' + user.id.toString()"
                ></router-link>
                <button
                  class="uk-button uk-button-default"
                  uk-icon="pencil"
                  href="#edit-user-modal"
                  uk-toggle
                  @click="setEditMode(user)"
                ></button>
                <button
                  class="uk-button uk-button-default"
                  uk-icon="trash"
                  href="#delete-user-modal"
                  uk-toggle
                  @click="setEditMode(user)"
                ></button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="uk-margin-small-top uk-hidden@m">
      <div class="" uk-grid>
        <div v-for="user in users" :key="user.id" class="uk-width-1-1">
          <div
            class="uk-card uk-card-default uk-padding-small uk-border-rounded"
          >
            <div class="uk-text-bold uk-text-large">
              {{ user.first_name }} {{ user.last_name }}
              <span v-if="user.nickname"> - {{ user.nickname }}</span>
            </div>
            <div v-if="user.active === 1" class="uk-text-success">Activo</div>
            <div v-else>Inactivo</div>
            <div>
              <span class="uk-text-bold">Correo:</span>
              {{ user.email }}
            </div>
            <div v-if="user.business">
              <span class="uk-text-bold">Empresa:</span>
              {{ user.business.name }}
              <span v-if="user.job_title">- {{ user.job_title }} </span>
            </div>
            <div>
              <span class="uk-text-bold">Tipo:</span>
              {{ user.type.name }}
            </div>
            <div class="uk-text-center uk-margin-small-top">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <router-link
                  class="uk-button uk-button-primary"
                  uk-icon="expand"
                  :to="'/users/' + user.id.toString()"
                ></router-link>
                <button
                  class="uk-button uk-button-secondary"
                  uk-icon="pencil"
                  href="#edit-user-modal"
                  uk-toggle
                  @click="setEditMode(user)"
                ></button>
                <button
                  class="uk-button uk-button-primary"
                  uk-icon="trash"
                  href="#delete-user-modal"
                  uk-toggle
                  @click="setEditMode(user)"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="edit-user-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Crear</span>
          <span v-else>Editar</span>
          usuario
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider
            name="Nombres"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Nombres</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Nombres"
              v-model="model.first_name"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider
            name="Apellidos"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Apellidos</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Apellidos"
              v-model="model.last_name"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider name="Apodo" rules="" v-slot="{ errors }">
            <div class="uk-margin-top">Apodo</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Apodo"
              v-model="model.nickname"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider name="Puesto" rules="" v-slot="{ errors }">
            <div class="uk-margin-top">Puesto</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Puesto"
              v-model="model.job_title"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider name="Teléfono" rules="" v-slot="{ errors }">
            <div class="uk-margin-top">Teléfono</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Teléfono"
              v-model="model.phone"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider name="Celular" rules="" v-slot="{ errors }">
            <div class="uk-margin-top">Celular</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Celular"
              v-model="model.mobile_phone"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider
            name="Correo"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Correo</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Correo"
              v-model="model.email"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider name="Tipo" rules="required" v-slot="{ errors }">
            <div class="uk-margin-top">Tipo</div>
            <select
              name="Tipo"
              v-model="model.type_id"
              class="uk-select uk-border-rounded"
            >
              <option v-for="type in userTypes" :key="type.id" :value="type.id">
                {{ type.name }}
              </option>
            </select>
            <div>{{ errors.length !== 0 ? errors[0] : "" }}</div>
          </ValidationProvider>

          <ValidationProvider
            v-if="model.type_id === 2"
            name="Empresa"
            rules=""
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Empresa</div>
            <v-select
              id="business-select"
              v-model="model.business_id"
              class="uk-input uk-border-rounded"
              :options="businesses"
              :reduce="(business) => business.id"
              :getOptionLabel="(option) => option.name"
            ></v-select>
            <div>{{ errors.length !== 0 ? errors[0] : "" }}</div>
          </ValidationProvider>

          <div class="uk-text-center uk-margin-top">
            <button
              class="uk-button uk-button-primary uk-border-rounded"
              :disabled="invalid || isLoadingUsers"
              @click="mode === 'new' ? store() : update()"
            >
              <span v-if="isLoadingUsers" uk-spinner></span>
              <span v-else>
                <span v-if="mode === 'new'">Crear</span>
                <span v-else>Editar</span>
                usuario
              </span>
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>

    <div id="delete-user-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
        v-if="selectedUser"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          ¿Está seguro que desea eliminar al usuario
          {{ selectedUser.first_name }} {{ selectedUser.last_name }}?
        </div>

        <div class="uk-text-center uk-margin-top">
          <button
            class="uk-button uk-button-danger uk-border-rounded"
            @click="destroy"
            :disabled="isLoadingUsers"
          >
            <span v-if="isLoadingUsers" uk-spinner></span>
            <span v-else>Eliminar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";

const blankModel = {
  first_name: "",
  last_name: "",
  nickname: "",
  job_title: "",
  phone: "",
  mobile_phone: "",
  type_id: "",
  email: "",
  business_id: "",
};

export default {
  name: "UsersIndex",

  data() {
    return {
      mode: "new",
      model: this._.cloneDeep(blankModel),
      users: null,
      userTypes: null,
      isLoadingUsers: null,
      businesses: null,
      isLoadingBusinesses: null,
      selectedUser: null,
    };
  },

  mounted() {
    this.fetchBusinesses();
    this.fetchUsers();
  },

  methods: {
    fetchBusinesses() {
      this.isLoadingBusinesses = true;
      this.axios
        .get("/businesses")
        .then(({ data }) => {
          this.businesses = data.businesses;
        })
        .finally(() => {
          this.isLoadingBusinesses = false;
        });
    },
    resetModel() {
      this.model = this._.cloneDeep(blankModel);
    },
    fetchUsers() {
      this.isLoadingUsers = true;
      this.axios
        .get("/users")
        .then(({ data: { users, userTypes } }) => {
          this.users = users;
          this.userTypes = userTypes;
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoadingUsers = false;
        });
    },
    setNewMode() {
      this.mode = "new";
      this.selectedUser = null;
      this.resetModel();
    },
    setEditMode(user) {
      this.mode = "edit";
      this.selectedUser = user;
      this.model.first_name = user.first_name;
      this.model.last_name = user.last_name;
      this.model.nickname = user.nickname;
      this.model.job_title = user.job_title;
      this.model.phone = user.phone;
      this.model.mobile_phone = user.mobile_phone;
      this.model.email = user.email;
      this.model.type_id = user.type_id;
      this.model.business_id = user.business_id;
    },
    store() {
      this.isLoading = true;
      this.axios
        .post("/users", this.model)
        .then(() => {
          this.refetchData();
          UIkit.modal("#edit-user-modal").hide();
          this.resetModel();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      this.axios
        .put("/users/" + this.selectedUser.id.toString(), this.model)
        .then(() => {
          this.refetchData();
          UIkit.modal("#edit-user-modal").hide();
          this.resetModel();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    destroy() {
      this.isLoading = true;
      this.axios
        .delete("/users/" + this.selectedUser.id.toString())
        .then(() => {
          this.refetchData();
          UIkit.modal("#delete-user-modal").hide();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
